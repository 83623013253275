import { Component, createRef } from 'inferno';
import 'sanitize.css';
import './App.css';
import Logo from './logo';
import teamImg from './images/atmende-buecher.jpg';
import SliderIndicators from './header/slider-indicators';
import ChevronDown from './icons/ChevronDown';
import Hammer from 'hammerjs';
import WheelIndicator from 'wheel-indicator';
class App extends Component {
  constructor(props) {
    super(props)
    this.element = createRef()
  }
  state = {
    headerIsOpen: false,
    sliderImages: [
      {
        img: 'Die-Feder-eines-Greifs',
        url: 'https://atmende-buecher.de/drachenreiter/die-feder-eines-greifs/',
        color: 'green',
        header: ['Die Feder eines Greifs'],
        headerMargin: '-76px 0 196px 0',
        productThumb: 'Die-Feder-eines-Greifs-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DrachenreiterDieFedereinesGreifs',
      },
      {
        img: 'Die-Vulkan-Mission',
        url: 'https://dievulkanmission.atmende-buecher.de/',
        color: 'blue',
        header: ['Die', 'Vulkan-Mission'],
        headerMargin: '-56px 0 196px 0',
        productThumb: 'Die-Vulkan-Mission-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DrachenreiterDieVulkanMission',
      },
      {
        img: 'The-Griffins-Feather',
        url: 'https://atmende-buecher.com/',
        color: 'orange',
        header: ['The', "Griffin's Feather"],
        headerMargin: '-10px 0px 146px',
        productThumb: 'The-Griffins-Feather-thumb',
        targetUrl:
          'https://www.amazon.de/Das-Labyrinth-Fauns-Pans/dp/3981653939/ref=asc_df_3981653939/?tag=googshopde-21&linkCode=df0&hvadid=354770969201&hvpos=&hvnetw=g&hvrand=2704860358863027156&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9061055&hvtargid=pla-779657183722&psc=1&th=1&psc=1&tag=&ref=&adgrpid=72063795099&hvpone=&hvptwo=&hvadid=354770969201&hvpos=&hvnetw=g&hvrand=2704860358863027156&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9061055&hvtargid=pla-779657183722',
      },
      {
        img: 'Das-Labyrinth-des-Fauns',
        url: 'http://daslabyrinthdesfauns.de/',
        color: 'turkish',
        header: ['Das', 'Labyrinth des', 'Fauns'],
        headerMargin: '-66px 0 196px 0',
        productThumb: 'Das-Labyrinth-des-Fauns-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DasLabyrinthdesFauns',
      },
      {
        img: 'Drachenreiter',
        url: 'https://atmende-buecher.de/drachenreiter/',
        color: 'blue',
        header: ['Drachenreiter'],
        headerMargin: '-76px 0 196px 0',
        productThumb: 'Drachenreiter-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/Drachenreiter',
      },
      {
        img: 'Palast-aus-Glas',
        url: 'https://atmende-buecher.de/palast-aus-glas/',
        color: 'dark-blue',
        header: ['Palast aus Glas'],
        headerMargin: '-76px 0 196px 0',
        productThumb: 'Palast-aus-Glas-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/PalastausGlas',
      },
      {
        img: 'Reckless',
        url: 'https://atmende-buecher.de/reckless/',
        color: 'dark-orange',
        header: ['Reckless'],
        headerMargin: '32px 0 216px 0',
        productThumb: 'Reckless-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/AufsilbernerFaehrte',
      },
      {
        img: 'Der-Fluch-der-Aurelia',
        url: 'https://atmende-buecher.de/drachenreiter/der-fluch-der-aurelia',
        color: 'gold',
        header: ['Der Fluch', 'der Aurelia'],
        headerMargin: '0 0 240px 0',
        productThumb: 'Der-Fluch-der-Aurelia-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DrachenreiterDerFluchderAurelia',
      },
      {
        img: 'Die-wilden-Huehner',
        url: 'https://atmende-buecher.de/diewildenhuehner?episode=1',
        color: 'white',
        header: ['Die wilden', 'Hühner'],
        headerMargin: '0 0 240px 0',
        productThumb: 'Die-wilden-Huehner-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DieWildenHuehner',
      },
      {
        img: 'Die-wilden-Huehner-02',
        url: 'https://atmende-buecher.de/diewildenhuehner?episode=2',
        color: 'white',
        header: ['Die wilden', 'Hühner'],
        headerMargin: '0 0 240px 0',
        productThumb: 'Die-wilden-Huehner-02-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DiewildenHuehner2',
      },
      {
        img: 'Die-wilden-Huehner-03',
        url: 'https://atmende-buecher.de/diewildenhuehner?episode=3',
        color: 'white',
        header: ['Die wilden', 'Hühner'],
        headerMargin: '0 0 240px 0',
        productThumb: 'Die-wilden-Huehner-03-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DieWildenHuehner3',
      },
      {
        img: 'Das-gruene-Koenigreich',
        url: 'https://atmende-buecher.de/dasgruenekoenigreich',
        color: 'white',
        header: ['Das grüne', 'Königreich'],
        headerMargin: '0 0 240px 0',
        productThumb: 'Das-gruene-Koenigreich-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/CorneliaFunkeDasgrueneKoenigreich',
      },
      {
        img: 'Die-Farbe-der-Rache_neu',
        url: 'https://atmende-buecher.de/diefarbederrache/',
        color: 'turkish',
        header: [],
        headerMargin: '66px 0 196px 0',
        productThumb: 'Die-Farbe-der-Rache-thumb',
        targetUrl:
          'https://atmendebuecher.lnk.to/DieFarbederRacheTintenweltBand4',
      },
    ],
    activeIndicatorId: 0,
    orientation: 'portrait',
  };

  isSliding = false;
  slideInterval = null;
  slideIntervalTimeout = null;

  thumbList = this.state.sliderImages
    .map((item) => item)
    .sort(function (a, b) {
      return 0.5 - Math.random();
    });

  recreateSlideInterval = () => {
    this.slideInterval = setInterval(() => {
      let idc = document.getElementsByClassName('indicators-list')[0].children;
      if (this.state.activeIndicatorId === idc.length - 1) {
        idc[0].click();
      } else {
        idc[this.state.activeIndicatorId + 1].click();
      }
      this.isSliding = true;
      //console.log('slide...', idc);
    }, 8000);
  };

  recreateSlideIntervalTimeout = () => {
    this.slideIntervalTimeout = setTimeout(() => {
      this.recreateSlideInterval();
    }, 10000);
  };

  componentWillMount() {
    //console.log('comp will mount!');
    this.setState({
      orientation: this.props.isPortrait() === true ? 'portrait' : 'landscape',
    });

    // Event listener for orientation change
    window.addEventListener('orientationchange', (evt) => {
      setTimeout((_) => {
        this.setState({
          orientation:
            this.props.isPortrait() === true ? 'portrait' : 'landscape',
        });
      }, 750);
    });
  }

  componentDidMount() {
    const sliderList = document.getElementsByClassName('slider-list')[0];
    const loaderContainer = document.getElementById('loader-container');
    const { sliderImages } = this.state;
    const sliderImagesCount = sliderImages.length;
    const indicator = new WheelIndicator({
      elem: sliderList,
      callback: this.wheelOnSlide,
    });
    indicator.setOptions({
      preventMouse: false,
    });

    let tmpImg = new Image();
    tmpImg.onload = () => {
      loaderContainer.classList.add('done-loading');
      this.recreateSlideInterval();
    };
    tmpImg.src = 'img/' + sliderImages[sliderImagesCount - 1].img + '_.jpg';

    // Event listener for animation end on slides
    Array.from(sliderList.children).forEach((item) => {
      item.addEventListener(this.props.animationEvent(), (event) => {
        const name = event.animationName;
        //console.log(name);
        const target = event.target;
        if (name.indexOf('Out') !== -1) {
          target.classList.remove('active');
        }
        if (name.indexOf('In') !== -1) {
          this.isSliding = false;
        }
        target.classList.remove(event.animationName, 'acitve');
        this.setActiveSliderId();
      });
    });

    // Event listener for swipe stuff

    this.hammer = Hammer(sliderList);
    this.hammer.on('swipeleft', () => {
      if (this.isSliding) return false;
      clearInterval(this.slideInterval);
      clearTimeout(this.slideIntervalTimeout);

      const currEl = sliderList.querySelector('.active');
      const prevEl = currEl.previousSibling;
      //const nextEl = currEl.nextSibling;
      const children = sliderList.children;
      //const first = children[0];
      const last = children[children.length - 1];
      currEl.classList.add('animateOutLeft');
      if (prevEl) {
        prevEl.classList.add('animateInRight', 'active');
      } else {
        last.classList.add('animateInRight', 'active');
      }
      this.recreateSlideIntervalTimeout();
      //console.log(currEl);
    });
    this.hammer.on('swiperight', () => {
      if (this.isSliding) return false;
      clearInterval(this.slideInterval);
      clearTimeout(this.slideIntervalTimeout);

      const currEl = sliderList.querySelector('.active');
      //const prevEl = currEl.previousSibling;
      const nextEl = currEl.nextSibling;
      const children = sliderList.children;
      const first = children[0];
      //const last = children[children.length - 1];
      currEl.classList.add('animateOutRight');
      if (nextEl) {
        nextEl.classList.add('animateInLeft', 'active');
      } else {
        first.classList.add('animateInLeft', 'active');
      }
      this.recreateSlideIntervalTimeout();
      //console.log(currEl);
    });
  }

  componentWillUnmount() {
    // stop when not renderable
    clearInterval(this.slideInterval);
    clearTimeout(this.slideIntervalTimeout);
  }

  revealClick = (event) => {
    this.setState((prevState) => ({
      headerIsOpen: !prevState.headerIsOpen,
    }));
  };

  setActiveSliderId = (el) => {
    let activeSliderId = Array.from(
      document.getElementsByClassName('slider-list')[0].children
    )
      .reverse()
      .findIndex((item) => item.classList.contains('active'));
    this.setState({
      activeIndicatorId: activeSliderId,
    });
  };

  wheelOnSlide = (event) => {
    if (this.isSliding) return false;
    this.isSliding = true;
    clearInterval(this.slideInterval);
    clearTimeout(this.slideIntervalTimeout);
    //const delta = Math.sign(event.deltaY);
    const sliderList = document.getElementsByClassName('slider-list')[0];

    //const currEl = event.currentTarget;
    //const prevEl = currEl.previousSibling;
    //const nextEl = currEl.nextSibling;
    //const parent = currEl.parentElement;
    //const children = parent.children;
    //const first = children[0];
    //const last = children[children.length - 1];

    if (event.direction === 'up') {
      const currEl = sliderList.querySelector('.active');
      //const prevEl = currEl.previousSibling;
      const nextEl = currEl.nextSibling;
      const children = sliderList.children;
      const first = children[0];
      //const last = children[children.length - 1];
      currEl.classList.add('animateOutRight');
      if (nextEl) {
        nextEl.classList.add('animateInLeft', 'active');
      } else {
        first.classList.add('animateInLeft', 'active');
      }
      this.recreateSlideIntervalTimeout();
      //console.log(currEl);
      /*
      this.isSliding = true;
      currEl.classList.add('animateOutRight');
      if (nextEl) {
        nextEl.classList.add('animateInLeft', 'active');
      } else {
        first.classList.add('animateInLeft', 'active');
      }
      */
    }
    if (event.direction === 'down') {
      const currEl = sliderList.querySelector('.active');
      const prevEl = currEl.previousSibling;
      //const nextEl = currEl.nextSibling;
      const children = sliderList.children;
      //const first = children[0];
      const last = children[children.length - 1];
      currEl.classList.add('animateOutLeft');
      if (prevEl) {
        prevEl.classList.add('animateInRight', 'active');
      } else {
        last.classList.add('animateInRight', 'active');
      }
      this.recreateSlideIntervalTimeout();
      //console.log(currEl);

      /*
      this.isSliding = true;
      currEl.classList.add('animateOutLeft');
      if (prevEl) {
        prevEl.classList.add('animateInRight', 'active');
      } else {
        last.classList.add('animateInRight', 'active');
      }
      */
    }

    //this.recreateSlideIntervalTimeout();
  };

  onIndicatorClick = (event) => {
    const el = event.currentTarget;
    const parent = el.parentElement;
    const children = parent.children;
    const oldIdx = this.state.activeIndicatorId;
    const index = [...children].indexOf(el);
    const slider = Array.from(
      document.getElementsByClassName('slider-list')[0].children
    ).reverse();

    if (oldIdx < index) {
      this.isSliding = true;
      slider[oldIdx].classList.add('animateOutLeft');
      slider[index].classList.add('animateInRight', 'active');
    } else {
      this.isSliding = true;
      slider[oldIdx].classList.add('animateOutLeft');
      slider[index].classList.add('animateInRight', 'active');
      /*
      slider[oldIdx].classList.add('animateOutRight');
      slider[index].classList.add('animateInLeft', 'active');
      */
    }
  };

  onProductClick = (event) => {
    var win = window.open(event.currentTarget.dataset.targetUrl, '_blank');
    win.focus();
  };

  renderSliderImages(orientation) {
    return this.state.sliderImages.map((img, idx, arr) => {
      return (
        <li
          style={{
            background:
              orientation === 'portrait'
                ? `url(img/${img.img}-Portrait-compressor_.jpg) top center / cover no-repeat scroll`
                : `url(img/${img.img}_.jpg) top center / cover no-repeat scroll`,
          }}
          data-id={idx + 1}
          //onwheel={this.wheelOnSlide}
          className={idx === arr.length - 1 ? 'active' : ''}
        >
          <div className="center v-align-parent">
            <div className="v-align-child">
              <h1
                className={'mobile-view-heading hidden-lg ' + img.color}
                style={{
                  margin: img.headerMargin,
                }}
              >
                {img.header.map((head) => (
                  <span>
                    {head}
                    <br />
                  </span>
                ))}
              </h1>
              <a
                className={
                  img.color === 'white'
                    ? `button ${img.color}-bg white-text`
                    : `button ${img.color}-bg`
                }
                href={img.url}
                target="_blank"
              >
                Erfahre mehr
              </a>
            </div>
          </div>
        </li>
      );
    });
  }

  render() {
    return (
      <>
        <div id="loader-container">
          <div id="loader">
            <div class="lds-ripple">
              <div />
              <div />
            </div>
          </div>
        </div>
        <div ref={this.element} className="cta">
          <div className="qr-codes">
          <div>
              <h2>Reckless AiR. Eine Reise durch den Spiegel – die interaktive App</h2>
              <p>Hole dir das erste interaktive 3D Hör-Abenteuer von Cornelia Funke, bei dem du selbst den Lauf der Geschichte bestimmen kannst!</p>
              <a href="https://apps.apple.com/de/app/reckless-air/id1597350643" target='_blank'><img src="/img/qr-codes/reckless_app.jpg" alt="" width={200} height={200} /></a>
            </div>
            <div>
              <h2>»Tintenwelt 4. Die Farbe der Rache« ab sofort hören!</h2>
              <p>Tauche ein in das neue Abenteuer aus der Tintenwelt und höre das Hörbuch als Download oder Stream!</p>
              <a href="https://atmendebuecher.lnk.to/DieFarbederRacheTintenweltBand4" target='_blank'><img src="/img/qr-codes/Die_Farbe_der_Rache.jpg" alt="" width={200} height={200} /></a>
            </div>
          </div>
          <div className="qr-codes-dismiss">
            <button onClick={(evt) => {
              evt.preventDefault()
              this.element.current.classList.add('hide-cta')
            }} className="button gold-bg" style={{borderWidth: '0', outline: 'none'}}>Schließen</button></div>
        </div>
        <div id="header" className={this.state.headerIsOpen ? 'open' : ''}>
          <SliderIndicators
            items={this.state.sliderImages}
            activeIndicatorId={this.state.activeIndicatorId}
            onIndicatorClick={this.onIndicatorClick}
            headerIsOpen={this.state.headerIsOpen}
          />
          <div
            className={
              this.state.headerIsOpen
                ? 'slider-container hide'
                : 'slider-container'
            }
          >
            <ul className="slider-list">
              {/*this.state.orientation === 'portrait'
                ? this.renderPortraitSlider()
          : this.renderLandscapeSlider()*/}
              {this.renderSliderImages(this.state.orientation)}
            </ul>
          </div>

          <Logo width={150} />

          <button
            id="reveal"
            className={this.state.headerIsOpen ? '' : 'bounce'}
            onClick={this.revealClick}
          >
            <div>
              <ChevronDown
                size="42"
                className={
                  this.state.headerIsOpen
                    ? 'reveal-chev dismiss'
                    : 'reveal-chev'
                }
              />
              <ChevronDown
                size="42"
                className={
                  this.state.headerIsOpen
                    ? 'reveal-chev dismiss'
                    : 'reveal-chev'
                }
              />
            </div>
          </button>

          <button id="reveal-mobile" className="bounce">
            <div>
              <ChevronDown size="42" className="reveal-chev" />
            </div>
          </button>
        </div>
        <div className="main-content">
          <div id="about">
            <h2 className="content-title">Atmende Bücher</h2>
            <h4 className="content-subtitle">
              Wer oder was sind Atmende Bücher?
            </h4>
            <img
              className="responsive"
              src={teamImg}
              alt="Atmende Bücher Team"
            />
            <small>
              Das Team von Atmende Bücher (von li. nach re.): Cornelia Funke,
              Julia Meier, Donate Altenburger, Eduardo García
            </small>
            <h2 className="content-title">Was ist Atmende Bücher?</h2>
            <div className="text-content">
              <p>
                Cornelia Funke war schon immer fasziniert vom klingenden Wort.
                Kombiniert mit dem Wunsch nach mehr gestalterischer und
                wirtschaftlicher Freiheit hat sie den Schritt gewagt und
                zusammen mit Hörbuchproduzent Eduardo García das Label Atmende
                Bücher gegründet.
              </p>
              <p>
                Zusammen mit den Klangkünstlern von German Wahnsinn werden
                Cornelias Bücher nun zum Atmen gebracht: Geräusche, Musik und
                Stimme verspinnen sich zu einem Soundtrack, der den Hörer von
                Beginn an fesselt und in eine spannende Klangwelt entführt.
              </p>
            </div>
            <h2 className="content-title">Und darauf freuen wir uns …</h2>
            <div className="text-content">
              <p>
                Wir planen ein bis zwei Produktionen pro Jahr und folgen damit
                Cornelias Buchveröffentlichungen. Um unseren Hörern das Warten
                zu verkürzen, wollen wir aber zwischendurch auch kleinere Texte
                von Cornelia produzieren – dass solche Aktionen spontan möglich
                sind, macht für uns den besonderen Reiz von Atmende Bücher aus!
              </p>
            </div>

            <div className="product-list">
              {this.thumbList.map((img) => (
                <img
                  src={'img/' + img.productThumb + '.jpg'}
                  width="150"
                  height="150"
                  alt={img.productThumb}
                  data-target-url={img.targetUrl}
                  onClick={this.onProductClick}
                />
              ))}
            </div>

            <div className="socials">
              <a
                className="button brand-bg upper"
                href="https://www.facebook.com/atmendebuecher/"
                target="_blank"
              >
                <i class="soc-facebook" />
              </a>
              <a
                className="button brand-bg upper"
                href="https://www.instagram.com/atmende.buecher/"
                target="_blank"
              >
                <i class="soc-insta" />
              </a>
              <a
                className="button brand-bg upper"
                href="https://soundcloud.com/atmende-buecher"
                target="_blank"
              >
                <i class="soc-soundcloud" />
              </a>
            </div>
          </div>
          <div className="nav-container">
            <nav class="nav-footer-meta">
              <a
                href="impressum.html"
                class="nav-footer-meta-item"
                title="Zum Impressum"
                role="button"
              >
                Impressum
              </a>
              <a
                href="datenschutzerklaerung.html"
                class="nav-footer-meta-item"
                title="Zur Datenschutzerklärung"
                role="button"
              >
                Datenschutzerklärung
              </a>
              <a
                href="http://www.corneliafunke.com"
                target="_blank"
                class="nav-footer-meta-item"
                title="Zu corneliafunke.com"
                role="button"
              >
                corneliafunke.com
              </a>
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default App;
