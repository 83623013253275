/* From Modernizr */
function animationEvent() {
  var t;
  var el = document.createElement("fakeelement");
  var animations = {
    animation: "animationend",
    OAnimation: "oAnimationEnd",
    MozAnimation: "Animationend",
    WebkitAnimation: "webkitAnimationEnd"
  };

  for (t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
}

function isPortrait() {
  return window.innerHeight > window.innerWidth;
}

export { animationEvent, isPortrait };
