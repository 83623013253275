import "inferno";
//import PropTypes from "prop-types";

const SliderIndecators = props => {
  return (
    <ul
      className={
        props.headerIsOpen ? "indicators-list hidden" : "indicators-list"
      }
    >
      {props.items.map((_, idx, arr) => (
        <li
          className={
            idx === props.activeIndicatorId ? "indicator active" : "indicator"
          }
          onClick={props.onIndicatorClick}
        />
      ))}
    </ul>
  );
};

export default SliderIndecators;
