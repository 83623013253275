import { render } from "inferno";
//import state from "./state";
import { animationEvent, isPortrait } from "./utils";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

render(
  <App animationEvent={animationEvent} isPortrait={isPortrait} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
